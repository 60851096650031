@import "src/style/variables";

.title {
  font-size: 18px;
  color: $textColor;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subtitle {
  color: $inactiveTextColor;
  font-size: 16px;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.note {
  font-size: 14px;
  color: $textColor;
}

// TODO: move to global style
.textarea {
  resize: none;
  font-size: 16px;
  color: $textColor;
  border-radius: $borderRadius;
  border: none;
  outline: none;
  box-shadow: 0 0 0 2px transparent;
  background-color: #F0F4F8;
  transition: background-color 0.2s linear,  box-shadow 0.2s linear;
  padding: 0 12px;

  &:focus {
    box-shadow: 0 0 0 2px $activeFormElementColor;
    background-color: $white !important;
  }

  &:hover {
    background-color: $interactiveInputColor;
  }

  &::placeholder {
    color: #979BA9;
  }
}

.divider {
  height: 1px;
  background-color: #DDE2ED;
}

.overlay {
  background-color: rgba(199, 199, 199, 0.2);
}