@import 'src/style/variables';

.animatedInput {
  height: 48px;
  font-size: 16px;
  border-radius: $borderRadius;
  border: none;
  box-shadow: 0 0 0 2px transparent;
  background-color: #F0F4F8;
  color: $textColor;
  transition: background-color 0.2s linear,  box-shadow 0.2s linear;

  &:focus,
  &.inputSuccess,
  &.inputError:valid {
    box-shadow: 0 0 0 1px $secondary;
    background-color: $white !important;

    & + .animatedLabel {
      top: 0;
      transform: translateY(-50%);
      background-color: $white;

      & img, & svg {
        display: none;
      }
    }
  }
  &.inputSuccess + .animatedLabel {
    color: $secondary;
  }

  &.inputError + .animatedLabel {
    color: $danger;
  }

  &.inputError {
    box-shadow: 0 0 0 1px $danger, 0 0 0 1px transparent !important;
  }

  &:hover {
    background-color: $interactiveInputColor;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus{
    font-size: 16px;
    border-radius: $borderRadius;
    border: 1px solid $secondary;
    -webkit-text-fill-color: $textColor;
    -webkit-box-shadow: 0 0 0 1000px $white inset;
    transition: background-color 5000s ease-in-out 0s;

    & + .animatedLabel {
      top: 0;
      transform: translateY(-50%);
      background-color: $white;
    }
  }
}

.animatedLabel {
  left: 9px;
  top: 50%;
  padding: 0 4px;
  transform: translateY(-50%);
  transition: transform 0.2s linear, top 0.2s linear;
  font-size: 14px;
  color: $inactiveTextColor;
}

.errorText {
  color: $danger;
  font-size: 12px;
  transform: translateY(2px);
  display: block;
  width: 100%;
  background: $white;
}

.filledIcon {
  & path {
    fill: $textColor;
  }

  & circle {
    fill: $white;
  }
}
