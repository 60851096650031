@import 'custom';
@import 'variables';
@import 'map';
@import 'datePicker';
@import 'pagination';
@import 'treeDropdown';
@import 'carousel';
@import 'progressBar';

@font-face {
  font-family: 'Söhne-Buch';
  src: local('Söhne'), url(../assets/fonts/soehne-buch.woff2) format('woff2');
}

@font-face {
  font-family: 'Söhne-Kraftig';
  src: local('Söhne'), url(../assets/fonts/soehne-kraftig.woff2) format('woff2');
}

@font-face {
  font-family: 'Söhne-Halbfett';
  src: local('Söhne'), url(../assets/fonts/soehne-halbfett.woff2) format('woff2');
}

body {
  font-family: Söhne-Buch, serif;
  user-select: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6, b {
  font-family: 'Söhne-Kraftig', serif;
}

* {
  &:focus {
    outline: 2px $activeFormElementColor solid !important;
  }
}

.font-kraftig {
  font-family: 'Söhne-Kraftig', serif;
  font-weight: 400;
}

.font-halbfett {
  font-family: 'Söhne-Halbfett', serif;
  font-weight: 400;
}

.font-size-14 {
  font-size: 14px !important;
}

.default-color-text {
  color: $textColor !important;
}

.default-badge {
  min-width: 16px;
  height: 16px;
  border-radius: 30px;
  padding: 3px;
  background-color: $badge !important;
  font-size: 12px;
}

.default-shadow {
  box-shadow: 0px 1px 8px rgba(205, 210, 223, 0.7) !important;
}

.overlay-shadow {
  box-shadow: $overlayShadow;
}

.tooltip-inner {
  padding: 16px 24px;
  background-color: $white !important;
  color: $textColor !important;
  box-shadow: $overlayShadow;
}
.tooltip.show {
  opacity: 1;
}

//TODO: change to bootstrap spinner (https://getbootstrap.com/docs/5.0/components/spinners/) for example in a ChoosePlanModal component
.loader {
  display: inline-block;
  transform: translateZ(1px);

  & > div {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: $secondary;
    animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
}

.loader-hidden {
  display: none !important;
}

@keyframes lds-circle {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: rotateY(3600deg);
  }
}

.z-index-10 {
  z-index: 10;
}

.z-index-20 {
  z-index: 20;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-modal {
  background-color: rgba(11, 16, 41, 0.21);
}

.new-notification {
  width: 8px;
  height: 8px;
  background-color: $badge;
  left: 8px;
  top: 50%;
  margin-top: -4px;
}
