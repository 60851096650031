@import "src/style/variables";

.pagination-block {
  .page-item {
    font-size: 14px;
    border: 2px solid transparent;
    border-radius: $borderRadius;
    color: $inactiveTextColor;
    box-shadow: none;
    min-width: 24px;
    background-color: transparent;

    &:disabled {
      opacity: .4;
    }

    & svg {
      margin-top: -1px;
      &[data-direction="right"] {
        transform: rotate(-90deg) translateY(1px);
      }

      &[data-direction="left"] {
        transform: rotate(90deg) translateY(1px);
      }
    }

    &:hover:not([disabled]) {
      background-color: transparent;
      border: 2px solid $secondary;
    }

    &:focus {
      background-color: transparent;
    }
  }
}

ul.pagination {
  align-items: center;
  padding-top: 12px;
  border-top: 1px solid #DDE2ED;

  li {

    &:first-child a.page-link,
    &:last-child a.page-link {
      border: 2px solid  #E5E8EF;
    }

    a.page-link {
      font-size: 14px;
      border: 2px solid transparent;
      border-radius: $borderRadius;
      color: $inactiveTextColor;
      box-shadow: none;
      padding: 0 4px !important;
      min-width: 24px;

      & svg {
        &[data-direction="right"] {
            transform: rotate(90deg) translateY(1px);
        }

        &[data-direction="left"] {
          transform: rotate(-90deg) translateY(1px);
        }

      }

      &:hover {
        background-color: transparent;
        border: 2px solid $secondary;
      }

      &:focus {
        background-color: transparent;
      }

    &.active {
      background-color: transparent;
      border: 2px solid $secondary;
      box-shadow: none;
      color: $textColor;
    }
    }
  }
}

.pagination-item {
  height: 24px;
}

.pagination-navigation-btn {
  height: 24px;
}