@import 'src/style/variables';

.title {
  font-size: 30px;
  line-height: 1.65;
  margin: 0;
  color: $textColor;
}

.preRegisterTitle {
  font-size: 24px;
}

.loginTitle {
  margin-bottom: 30px;
}

.subTitle {
  font-size: 16px;
  line-height: 1.5;
  color: $inactiveTextColor;
  font-weight: 400;
}

.resetSubtitle {
  max-width: 284px;
}

.divider {
  height: 1px;
  background-color: #EFF0F2;
}

.buttonWrapper {
  width: 48%;
}

.use {
  text-transform: uppercase;
  color: $textColor;
  font-size: 14px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  & b {
    position: relative;
    background-color: $white;
    z-index: 99;
  }

  & .bgDark {
    background-color: $headerBgColor;
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    background-color: #DDE2ED;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

  }
}

.form {
  box-shadow: 0 1px 16px rgba(40, 61, 108, 0.14);
  border-radius: 2px;
  width: 536px;
}

.preRegisterForm {
  width: 476px;
}

.googleBtnOrigin {
  top: 1px;
  height: 50px;
}

.googleBtn:hover,
.googleBtn:focus {
  background-color: initial;
}

.googleBtnOrigin:hover ~ .customBtn {
  transition: background-color 0.2s linear;
  background-color: $interactiveInputColor !important;
}

.socialAuthBtn {
  padding: 10px 0;
}

.text {
  color: $textColor;
}

.forgot {
  text-align: right;
  margin-top: 5px;
  font-size: 16px;
  border-bottom: 1px solid transparent;
  transition: border-bottom-color 0.2s linear;

  & b {
    font-weight: 500;
  }

  &:hover {
    border-bottom-color: $secondary;
  }
}

.px40 {
  padding: 0 40px;
}

.mt100 {
  margin-top: 100px;
}

.input {
  padding: 12px 8px !important;
}

.preInput::placeholder {
  color: $inactiveTextColor;
  font-size: 14px;
}

.formBtn {
  padding: 11px 8px !important;
  font-family: Söhne-Kraftig, serif;
}

.modalWrapper {
  opacity: 0;
  z-index: -1;
  transition: opacity 0.2s linear;

  &.active {
    opacity: 1;
    z-index: 9999;
    background-color: rgba(11, 16, 41, 0.21);
  }
}

.closeIcon {
  top: 25px;
  right: 22px;
}

.link {
  font-size: 16px;
  font-family: Söhne-Kraftig, serif;
  border-bottom: 1px solid transparent !important;
  transition: border-bottom-color 0.2s linear;

  &:hover {
    border-bottom-color: $secondary !important;
  }
}

.label {
  color: $inactiveTextColor;
  font-size: 14px;
}

.select {
  background-color: #F0F4F8;
  box-shadow: none !important;
  height: 48px !important;
}

.errorMessage {
  color: $danger;
  font-size: 12px;
  transform: translateY(2px);
  background: $white;
}

.placeholder {
  font-size: 14px;
  color: $inactiveTextColor;
}

.checkboxText {
  font-size: 14px;
  color: $textColor;
}

.privacy {
  color: $secondary;
  font-family: inherit;
}

.light:hover,
.light:focus {
  background-color: lighten($headerBgColor, 10%);
}

.extendedWrapper {
  width: 536px;
  background-image: url("../../assets/img/auth/register-extended-bg-min.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  &:after {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    background-color: rgba($secondary, 0.8);
  }
}

.extendedBody {
  z-index: 1;
}

.extendedTitle {
  padding: 60px 80px 0;
  font-size: 24px;
}

.extendedList {
  padding: 0 80px 0 100px;
  list-style-image: url("../../assets/icons/tick-white.svg");
  font-size: 18px;
}