@import '../../style/variables';

.wrapper {
  height: 100%;
}

.container {
  width: 820px;
}

.titleWrapper {
  max-width: 564px;
}

.title {
  color: $textColor;
  font-size: 30px;
  line-height: 1;
}

.subtitle {
  color: $textColor;
  font-size: 16px;
  margin-bottom: 36px;
  font-weight: 400;
}

.searchInputWrapper {
  height: 56px;
  @media (max-height: 850px) {
    margin-bottom: 0 !important;
  }
}

.inputSearchOverlayText {
  color: $inactiveTextColor;
  transition: background-color 0.2s linear;

  &:hover {
    background-color: #e3f2ff;
  }
}

.searchTextWrapper {
  z-index: 1;
}

.searchImg {
  @media (max-height: 850px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.4;
  }
}