@import 'src/style/variables';

.generalInfo {
  height: calc(100% - 189px);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: block;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    margin-top: 50px;
    margin-bottom: 50px;
    background-color: $white;
  }

  &::-webkit-scrollbar-thumb {
    background-color:$borderColor;
    width: 4px;
    border-radius: 56px;
  }
}

.detailList {
  height: 100px;
  flex-wrap: wrap
}

.detailItem {
  white-space: nowrap;
  text-overflow: ellipsis;
  page-break-inside: avoid;
  break-inside: avoid;
}

.selectText {
  user-select: text;
}
