@import 'src/style/variables';

.wrapper {
  padding: 24px;
  border-top: 1px solid $borderColor;
  transition: background-color 0.2s linear;
  background-color: $white;
  user-select: text;

  &:first-of-type {
    border-top: none;
  }

  &:last-of-type {
    border-bottom: 1px solid $borderColor;
  }
  
  &.active {
    background-color: #F5FAFE;
  }
}

.selectProject {
  background-color: #F5FAFE;
}

.favoriteIcon {
  cursor: pointer;

  &.filled {
    & path {
      stroke: $badge;
      fill: $badge;
    }
  }
}

.status {
  padding: 2px 10px;
  text-transform: uppercase;
  font-size: 12px;
  background: $white;
  border: 1px solid $borderColor;
  border-radius: $borderRadius;
}
.bold {
  font-size: 16px;
  color: $textColor;
}

.light {
  font-size: 16px;
  color: $inactiveTextColor;
  font-weight: 400;
}

.overflowText {
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #2F3139;
}

.description {
  color: $inactiveTextColor;
  font-size: 16px;
}

.estimated {
  font-size: 10px;
  color: $inactiveTextColor;
  transform: translateY(3px);
}

.button {
  background: $white;
  border: 2px solid $borderColor;
  border-radius: $borderRadius;
  color: $secondary;
  font-size: 14px;
  line-height: 1;
  padding: 4px 12px;
  transition: all 0.2s linear;

  &:hover {
    background-color: darken($white, 5%);
  }

  &.active {
    border-color: $secondary;
    position: relative;

    &:after {
      position: absolute;
      left: calc(100% + 17px);
      top: 50%;
      transform: translateY(-50%);
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 9px 8px 0;
      border-color: transparent $secondary transparent transparent;
    }
  }
}

.lockedWrapper {
  backdrop-filter: blur(4px);
}

.lockIcon {
  width: 100%;
  max-width: 46px;
  & svg path {
    fill: $headerBgColor;
  }
}
