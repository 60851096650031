@import 'src/style/variables';

.wrapper {
  z-index: 5;
}

.searchSelect {
  height: 32px;
  padding: 2px 32px 2px 15px;
  font-size: 14px;

  &.active {
    border: 2px solid $secondary;
  }

  &.opened {
    background-color: $secondary;
    color: $white;
  }
}

.categorySelect {
  padding: 2px 25px !important;
  & span {
    transform: translateX(-6px);
  }
}

.text {
  white-space: nowrap;
}

.subHeaderButton {
  padding: 2px 0;
}

.input {
  height: 28px;
  box-shadow: 0 0 0 2px #dbdfea;
  background-color: $white;
  max-width: 120px;
  font-size: 14px;

  &.active {
    box-shadow: 0 0 0 2px $secondary;
  }
}

.saveBtn {
  height: 32px;
  padding: 0 15px !important;
  font-size: 14px;
  z-index: 1;
  white-space: nowrap;
}

.saveModal {
  display: none;

  &.active {
    display: block;
  }
}

.triangle {
  height: 24px;
  width: 24px;
  transform: translate(35px, 50%) rotate(45deg);
  z-index: 1;
}

.saveWindow {
  width: 450px;
  transform: translateX(10px);
  box-shadow: 0 8px 10px rgba(81, 90, 105, 0.14),
  0 3px 14px rgba(81, 90, 105, 0.12);
  border-radius: $borderRadius;
}

.saveTitle {
  font-size: 18px;
  line-height: 1.4;
  color: $textColor;
}

.divider {
  background-color: $light;
  height: 1px;
}

.btn {
  font-size: 14px;
  padding: 5px 24px;

  &:last-of-type {
    margin-left: 16px;
  }
}

.dateWrapper {
  max-height: initial;
}

.dateOption {
  width: 180px;
}

.w0 {
  width: 0;
  display: none;
}

.datePicker {
  font-size: 11px;
}

.selectContent {
  width: 370px;
  max-height: initial;
}

.valuationTitle {
  font-size: 18px;
  color: $textColor;
}

.delimiterWrapper {
  height: 40px;
}

.valuationInputDelimiter,
.moreInputDelimiter {
  width: 12px;
  height: 2px;
  background-color: $textColor;
  margin: 0 10px;
  align-self: center;
}

.valuationItem {
  user-select: inherit;
  border-bottom: 1px solid transparent;
  transition: border-color 0.2s linear;

  &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  &:hover {
    border-color: $textColor;
  }
}

.moreContent {
  width: 390px;
  max-height: initial;
  overflow: visible;
  background-color: $white;
}

.moreTitle {
  color: $textColor;
  font-size: 14px;
  white-space: nowrap;
  flex-grow: 1;
}

.placeholder {
  color: $inactiveTextColor;
}

.moreSelect {
  width: 100%;
  max-width: 100px;
}

.statusSelect {
  max-width: 232px;
}

.moreField {
  height: 32px;
  background-color: #F0F4F8;
  border: none !important;
}

.countInput {
  height: 32px;
  max-width: 100px;
}

.moreBtn,
.valuationBtn {
  font-size: 14px;
  padding: 5px 0;
}

.valuationBtn:first-child {
  &:hover, &:focus {
    background-color: $white;
    color: $textColor ;
  }
}

.keywordsInput {
  width: 232px;

   &:focus {
     box-shadow: 0 0 0 2px $activeFormElementColor !important;
   }
}
