@import 'src/style/variables';

.wrapperTable {
  overflow: auto;
  max-height: calc(100vh - 150px);

  &::-webkit-scrollbar {
    display: block;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    margin-top: 50px;
    margin-bottom: 50px;
    background-color: $white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $borderColor;
    width: 4px;
    border-radius: 56px;
    transition: background-color 0.2s linear;
  }
}

.table {
  background: $white;
  box-shadow: 0 1px 8px rgba(205, 210, 223, 0.22);
  border-radius: $borderRadius;
  color: $textColor;
}

.tableHead {
  position: sticky;
  top: 0;
  box-shadow: 0px 1px 0px rgba(230, 240, 242, 1);

  th {
    background: $white;
  }
}

thead, tbody {
  z-index: 5;
}

tbody tr:first-child {
  border-top: none !important;
}

.tableHeadCell {
  font-size: 14px;
}

.tableHeadCell:first-child {
  padding-left: 24px;
  text-align: left;
}

.tableHeadCell:last-child {
  padding-right: 24px;
  text-align: right;

  & .cellHeader {
    justify-content: flex-end;
  }
}

.sortIconUp {
  transform: rotate(0deg);
}
.sortIconDown {
  transform: rotate(180deg);
}

.contactRow {
  margin-bottom: -24px;
}

.label {
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: $greyLabelColor;
  position: absolute;
  top: 8px
}

.contactLabel {
  font-size: 10px;
  line-height: 12px;
  color: $greyLabelColor;
  position: absolute;
  top: -15px;
}

.contactIcon {
  cursor: pointer;

  &:hover > path {
    stroke: red;
  }
}

.subTitle {
  font-size: 14px;
  line-height: 16px;
}

.additionalValue {
  color: $inactiveTextColor;
}

.description {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  padding-bottom: 2px;
  user-select: text;
}

.selectText {
  user-select: text;
}

.cellWidth {
  min-width: 110px;
}

.cell {
  position: relative;
}

.cell:first-child {
  padding-left: 24px;
  text-align: left;
}

.cell:last-child {
  padding-right: 24px;
  text-align: right;
}

.colorType {
  width: 12px;
  height: 12px;
}

.status {
  width: fit-content;
  border: 1px solid #DDE2ED;
  border-radius: $borderRadius;
  text-transform: uppercase;
  font-size: 12px;
}

.actionButton:not(:first-child) {
  margin-left: 16px;
}

.actionCell {
  min-width: max-content;
}

.shadow {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 40px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
  z-index: 1;
}

.noteMarker {
  width: 8px;
  height: 8px;
  background-color: $secondary;
}