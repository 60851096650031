@import '../../style/variables';

.optionWrapper {
  top: 100%;
  left: 0;
  width: 100%;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12),
  0 5px 5px -3px rgba(0, 0, 0, 0.2);
  border-top: none;
  position: absolute;
  z-index: 100;
  background-color: $white;
}

.category {
  background-color: #F7F8FA;
  font-size: 10px;
  line-height: 1.2;
  color: $inactiveTextColor;
  text-transform: uppercase;
  padding: 6px 0;
}

.option {
  padding: 8px 12px;
  border: none;
  background-color: $white;
  font-size: 14px;
  color: $textColor;
  cursor: pointer;

  &:hover {
    background-color: $interactiveInputColor;
  }
}