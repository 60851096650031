@import "src/style/variables";

.wrapper {
  background-color: $white;
  z-index: 99;
  width: 450px;
  box-shadow: 0 8px 10px rgba(81, 90, 105, 0.14),
  0 3px 14px rgba(81, 90, 105, 0.12);
}

.close {
  top: 15px;
  right: 15px;
}

.arrow {
  width: 24px;
  height: 24px;
  top: 25px;

  &.left {
    right: 100%;
    transform: translateX(80%) rotate(45deg);
  }

  &.right {
    left: 100%;
    transform: translateX(-80%)  rotate(45deg);
  }
}