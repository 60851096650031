.modal {
  background: rgba(11, 16, 41, 0.21);
}

.modalContent {
  width: 448px;
  box-shadow: 0px 8px 10px rgba(81, 90, 105, 0.14), 0px 3px 14px rgba(81, 90, 105, 0.12);
  border-radius: 2px;
}

.modalTitleWrapper {
  max-width: 90%;
}

.modalTitle {
  font-size: 18px;
  line-height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modalSubTitle {
  font-size: 14px;
  line-height: 16px;
}

.close {
  transform: translateY(-5px);
}