@import 'src/style/variables';

.subHeader {
  height: 56px;
}

.dropdownTitle {
  font-size: 18px;
  font-weight: bold;
}

.dropdownActiveTitle {
  color: $secondary;
}

.dropdownActiveIcon circle {
  fill: $secondary;
}

.value {
  white-space: nowrap;
  color: $textColor;
}

.subHeaderButton {
  padding: 2px 0;
  font-size: 14px;
  white-space: nowrap;
  height: 32px;
}

.tab {
  border-bottom: 1px solid #DDE2ED;
  padding: 0 24px;
}

.tab li {
  text-transform: capitalize;
  font-weight: bold;
  font-size: 14px;
}

.tableWrapper {
  background-color: $white;
  border-radius: $borderRadius;
}

.table {
  max-height: calc(100vh - 297px) !important;
}

.favoriteSelect {
  height: 28px;
  padding: 2px 0 2px 0;
}

.select {
  height: 32px;
  padding: 2px 25px !important;
  max-width: 150px;
  & span {
    transform: translateX(-6px);
  }

  &.active {
    border: 2px solid $secondary;
  }
}

.searchWrapper {
  height: 28px;
  box-shadow: 0 0 0 2px #dbdfea;
  border-radius: $borderRadius;
}

.confirmModal {
  width: 380px;

  h2 {
    font-size: 30px;
    line-height: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.modalBody {
  width: 300px;
}
