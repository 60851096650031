@import '../../style/variables';

.label {
  color: $textColor;
  font-family: Söhne-Kraftig, serif;
  margin-bottom: 10px;
  line-height: 1;
  font-weight: 500;
  font-size: 14px;
}

.input {
  border-radius: $borderRadius;
  border: none;
  box-shadow: 0 0 0 2px transparent;
  background-color: #F0F4F8;
  color: $textColor;
  transition: background-color 0.2s linear,  box-shadow 0.2s linear;

  &[type="password"] {
    &::placeholder {
      letter-spacing: initial;
    }
  }

  &:focus {
    box-shadow: 0 0 0 2px $activeFormElementColor;
    background-color: $white !important;

    & + .icon {
      opacity: 1;
    }

    & + .inputOverlay {
      opacity: 0;
    }
  }

  &:hover {
    background-color: $interactiveInputColor;
  }
}
.searchIcon {
  top: calc(50% - 8px);
  left: 16px;
}

.searchIconDark path {
  fill: #000000;
}
.searchIconLight path {
  fill: $white;
}

.icon {
  opacity: 0;
  transition: opacity 0.2s linear;

  & path, & circle {
    transition: fill 0.2s linear;
  }
}

.inputError {
  box-shadow: 0 0 0 1px $danger, 0 0 0 1px transparent;
}

.errorText {
  color: $danger;
  font-size: 12px;
  transform: translateY(2px);
  display: block;
  width: 100%;
  background: $white;
}

.inputOverlay {
  transition: 0.2s linear opacity, 0.2s linear background-color;
  z-index: 1;
  height: 100%;
  border-radius: $borderRadius;
}

.filledEye {
  fill: $textColor;
}

.filledCircle {
  fill: $white;
}
