@import 'src/style/variables';

.inputWrapper {
  max-width: 553px;
  width: 100%;
}

.link:focus {
  outline: none !important;
}

.searchInput {
  color: $white !important;
  background-color: rgba($white, 0.21) !important;

  &:focus {
    color: $textColor !important;
    background-color: $white !important;
  }
}
.inputSearchOverlayText {
  color: rgba($white, 0.8);
  height: 100%;
  transition: 0.2s linear background-color;

  &:hover {
    background-color: rgba($white, 0.21);
  }
}

.button {
  text-transform: uppercase;
  font-size: 12px;
}

.purchase:hover {
  background-color: darken(#00C47D, 10%) !important;
}

.nav {
  background-color: $headerBgColor;
  height: 56px;
  max-height: 56px;
  min-height: 56px;
  padding-top: 1px;
}

.logoutIcon path {
  stroke: white;
}

.dropdown {
  font-size: 14px;
}

.dropdownTitle {
  &.activeDropdown svg path {
    stroke: $secondary;
  }

  &:hover {
    & svg path {
      stroke: $secondary;
    }
  }
}
