@import 'src/style/variables';

.home {
  max-height: 100vh;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    transition: background-color 0.2s linear;
    background-color:$borderColor;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: darken($borderColor, 10%);
  }
}

.intro {
  width: 1080px;
  margin: 0 auto 72px;

  h1 {
    font-size: 56px;
    line-height: 64px;
  }

  p {
    font-size: 18px;
    color: $inactiveTextColor;
  }
}

.searchWrapper {
  width: 1032px;
  margin: auto;
  background-color: $white;
  box-shadow: 0 10px 80px rgba(20, 44, 106, 0.15);
  border-radius: 4px;
}

.searchIcon path {
  fill: $inactiveTextColor;
}

.inputSearchOverlayText {
  color: $inactiveTextColor;
}

.introWrapper {
  top: 15%;
}