@import 'src/style/variables';

.title {
  font-size: 24px;
  padding-top: 32px;
  line-height: 1.7;
}

.subTitle {
  font-size: 18px;
}

.planWrapper {
  box-shadow: 0 1px 8px rgba(205, 210, 223, 0.22);
  border-radius: $borderRadius;
}

.planIcon {
  width: 90px;
  height: 90px;
  position: relative;

  & img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-45%, -45%);
  }
}

.price {
  font-size: 16px;
  line-height: 2.5;
  color: $textColor;
  font-weight: 500;
}

.cancelBtn {
  font-size: 14px;
}

.label {
  color: $inactiveTextColor;
}

.cell {
  height: 40px;
}

.status {
  width: fit-content;
  border: 1px solid;
  border-radius: $borderRadius;
  text-transform: uppercase;
  font-size: 12px;
}

.actionCell {
  width: 400px;
}

.cancelBtn {
  border-bottom: 1px solid $inactiveTextColor;
}

.confirmModal {
  width: 434px;

  h2 {
    font-size: 26px;
    line-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }
}

.tableBtn {
  width: 192px;
}
