@import 'src/style/variables';

.success {
  background-color: #6DE0A9;
}

.error {
  background-color: $danger;
}

.customAlert {
  width: max-content;
  background-color: $white;
  box-shadow: $overlayShadow;
  border-radius: $borderRadius;
  color: $textColor;
  font-size: 14px;
  line-height: 24px;

  a {
    color: $secondary;
    text-decoration: none;
  }
}

.iconItem {
  width: 25px;
  height: 25px;
}

.alertWrapper {
  top: 8px;
  z-index: 9999;
}

.close {
  transform: translate(10px, -12px);
}
