@import "src/style/variables";

.container {
  background-color: $headerBgColor;
}

.form {
  padding: 85px 0;
}
.textWrapper {
  max-width: 480px;
}

.title {
  font-size: 48px;
}

.subTitle {
  font-size: 16px;
  margin-bottom: 136px;
  line-height: 2rem;
}

.copy {
  font-size: 16px;
}

.round {
  border: 2px solid rgba(255, 255, 255, 0.15);
  width: 490px;
  height: 490px;

  &:first-of-type {
    width: 257px;
    height: 257px;
  }

  &:last-of-type {
    width: 720px;
    height: 720px;
  }

}