@import '../../style/variables';

.navItem {
  font-size: 12px;
  font-weight: 600;
  line-height: 36px;
  text-transform: uppercase;

  & a:focus {
    outline: none !important;
  }
}

.lightNavLink {
  color: $inactiveTextColor !important;
  border-bottom: 3px solid transparent;

  &:hover {
    color: $textColor !important;
  }

  &.active {
    color: $textColor !important;
    border-bottom: 3px solid $secondary;
  }
}

.darkNavLink {
  color: $darkInactiveTextColor !important;
  border-bottom: 3px solid transparent;

  &:hover {
    color: $white !important;
  }

  &.active {
    color: $white !important;
    border-bottom: 3px solid $secondary;
  }
}
