@import 'src/style/variables';

.rightSidebarWrapper {
  background: rgba(11, 16, 41, 0.21);
  z-index: 10;
  overflow: hidden;
}

.rightSidebar {
  width: 656px;
  background-color: $white;
  z-index: 11;
}

.title {
  font-size: 18px;
}

.rightSidebarHeader {
  background-color: #F3F5F8;
}

.tab {
  border-top: 1px solid #DDE2ED;
  border-bottom: 1px solid #DDE2ED;
  padding: 0 24px;
}

.tab li {
  flex: 1 1 auto;
}

.favoriteIcon {
  cursor: pointer;

  &.filled {
    & path {
      stroke: $badge;
      fill: $badge;
    }
  }
}

.close {
  left: 9px;
}