@import 'src/style/variables';

.overlay {
  background: rgba(11, 16, 41, 0.21);
}

.modal {
  box-shadow: $overlayShadow;
  border-radius: 2px;
}

.modalBody {
  width: 300px;
}

.modalSubTitle {
  line-height: 20px;
  font-size: 16px;
  color: $inactiveTextColor;
}