@import 'src/style/variables';

.projectsWrapper {
  height: calc(100vh - 200px);
  overflow-y: scroll;
  background-color: $white;

   &::-webkit-scrollbar {
      width: 0;
   }

  @media screen and (max-width: 1550px) {
    height: calc(100vh - 238px);
  }
}

.projectsList {
  &::-webkit-scrollbar {
    display: block;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: $white;
  }

  &::-webkit-scrollbar-thumb {
    background-color:$borderColor;
    width: 4px;
    border-radius: 56px;
  }
}

.general {
  padding: 0 13px 0 24px;
  max-width: 100%;
  align-items: end;
  border-bottom: 1px solid $borderColor;

  @media screen and (max-width: 1550px) {
    flex-direction: column;
  }
}
.wrapper {
   border-right: 1px solid #DDE2ED;
}

.emptyResults {
  border-top: 1px solid #DDE2ED;
}

.infoWrapper {
  width: 55%;

  @media screen and (max-width: 1550px) {
    width: 100%;
    margin-bottom: 10px;
  }
}

.location {
  font-size: 20px;
  color: $textColor;
  user-select: text;

  & span {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.value {
  font-size: 14px;
}

//TODO: change to bootstrap spinner (https://getbootstrap.com/docs/5.0/components/spinners/) for example in a ChoosePlanModal component
.loader {
  display: inline-block;
  transform: translateZ(1px);

  & > div {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: $secondary;
    animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
}

.sort {
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1.2;
  color: $textColor;
}

.sortSelect {
  font-size: 12px;
  height: 32px;
  padding-right: 32px;
}

@keyframes lds-circle {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: rotateY(3600deg);
  }
}
