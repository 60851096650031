@import 'src/style/variables';


.container {
  max-width: 824px;
}

.title {
  font-size: 16px;
  color: $textColor;
}