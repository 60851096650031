@import '../../style/variables';

.navItem {
  padding: 8px 10px;
  font-size: 12px;
  line-height: 29px;
  text-transform: uppercase;
  cursor: pointer;

  &:last-child {
    margin: 0 !important;
  }
}

.lightNavLink {
  color: $inactiveTextColor;
  border-bottom: 3px solid transparent;
  transition: all .2s linear;

  &:hover, &:focus {
    color: $inactiveTextColor;
    border-bottom-color: $borderColor;
  }

  &.active {
    color: $textColor;
    border-bottom-color: $secondary;
  }
}

.darkNavLink {
  color: $darkInactiveTextColor;
  border-bottom: 3px solid transparent;

  &:hover, &:focus {
    color: $white;
  }

  &.active {
    color: $white;
    border-bottom-color: $secondary;
    filter: blur(0);
  }
}
