@import "src/style/variables";

.wrapper {
  width: 18px;
  height: 18px;
  background-color: $white;
  border: 2px solid $borderColor;
  border-radius: $borderRadius;
  transition: all 0.2s linear;
  
  &.active {
    background-color: $secondary;
    border-color: $secondary;
  }
}
