@import "src/style/variables";

.key {
  font-weight: 500;
}

.wrapper {
  background: rgba(11, 16, 41, 0.21);
  z-index: 10;
}

.body {
  width: 656px;
}

.iconText {
  color: $textColor;
  text-transform: uppercase;
  font-size: 12px;
}

.favoriteIcon {
  cursor: pointer;

  &.filled {
    & path {
      stroke: $badge;
      fill: $badge;
    }
  }
}

.shareIcon {
  transform: translateY(-3px);
}

.divider {
  background-color: $light;
  height: 1px;
}

.location {
  font-size: 20px;
  color: $textColor;
  user-select: text;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.close {
  transform: translateY(-2px);
  margin-left: 29px;
}

.description {
  user-select: text;
}

.openPermits {
  font-size: 14px;
  color: $inactiveTextColor;
}

.generalInfo {
  height: calc(100% - 211px);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: block;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color:$borderColor;
    width: 4px;
    border-radius: 56px;
  }

  &::-webkit-scrollbar-track {
    margin-top: 50px;
    margin-bottom: 50px;
    background-color: $white;
  }

  .status {
    padding: 2px 10px;
    text-transform: uppercase;
    font-size: 12px;
    background: $white;
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
  }

  .bold {
    font-size: 18px;
    color: $textColor;
  }

  .overflowText {
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #2F3139;
  }

  .light {
    font-size: 16px;
    color: $inactiveTextColor;
    font-weight: 400;
  }

  .permitType {
    color: $inactiveTextColor;
    font-size: 16px;
  }

  .estimated {
    font-size: 10px;
    color: $inactiveTextColor;
    transform: translateY(3px);
  }
}