@import 'react-date-range/dist/styles.css'; // main style file
@import 'react-date-range/dist/theme/default.css'; // theme css file

.rdrMonth {
  padding: 0 10px !important;
}

.rdrMonthAndYearWrapper {
  padding-top: 0 !important;
}


