@import 'src/style/variables';

.personItem {
  user-select: text;

  &:nth-child(odd) {
    padding-right: 16px;
  }
  &:nth-child(even) {
    padding-left: 16px;
  }
}

.avatar {
  width: 56px;
  height: 56px;
  background-color: #F5FAFE;
}

.personRole {
  font-size: 12px;
  color: $greyLabelColor;
}

.detailItem {
  white-space: nowrap;
  text-overflow: ellipsis;
  page-break-inside: avoid;
  break-inside: avoid;
}

.personInfo {
  width: calc(50% + 74px);
}
