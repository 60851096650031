@import 'src/style/variables';

.dropdownTitle {
  transition: color 0.2s linear;
  font-size: 18px;
  font-weight: 500;
}

.dropdownActiveTitle {
  color: $secondary;
}

.tab {
  font-weight: 500;
  & li {
    line-height: 36px;
  }
}

.subHeaderButton {
  padding: 2px 0;
  font-size: 14px;
  height: 32px;
}