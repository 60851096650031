@import 'src/style/variables';

.wrapper {
  background-color: #F7F8FA;
}

.icon {
  transform: rotate(-90deg);
}

.exportBtn {
  height: 32px;

  & img {
    transform: translateY(-1px);
  }
}

.export {
  font-size: 14px;
  color: $textColor;
}

.shadow {
  box-shadow: 0 1px 8px rgba(205, 210, 223, 0.7);
}

.tableWrapper {
  height: fit-content;
}

.btn {
  width: 32px;
  height: 32px;
  border: 2px solid #E5E8EF;
  border-radius: $borderRadius;
  font-size: 14px;

  &:last-child {
    width: auto;
    padding: 0 24px;
  }

  &.activeBtn {
    border-color: $secondary;
  }
}

.back {
  font-size: 18px;
  color: $textColor;
}

.tooltip {
  position: relative;
  border-radius: $borderRadius;
  box-shadow: 0px 8px 10px rgba(81, 90, 105, 0.14), 0px 3px 14px rgba(81, 90, 105, 0.12);

  &:after {
    content: '';
    position: absolute;
    top: -7px;
    width: 24px;
    height: 24px;
    border-radius: $borderRadius;
    transform: rotate(135deg);
    background-color: $white;
  }
}