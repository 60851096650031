@import 'src/style/variables';

.wrapper {
  background: $white;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12),
  0 5px 5px -3px rgba(0, 0, 0, 0.2);
  z-index: 9;

  overflow-y: scroll;
  max-height: 400px;

  &::-webkit-scrollbar {
    display: block;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: $white;
  }

  &::-webkit-scrollbar-thumb {
    background-color:$borderColor;
    width: 4px;
    border-radius: 56px;
  }
}

.list {

  li {
    cursor: pointer;
    font-size: 14px;
    border: 1px solid transparent;
    border-radius: $borderRadius;
    margin-bottom: 5px;

    &:hover {
      color: #0187FE;
      background-color: #F5FAFE;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.activeRow {
  color: #0187FE;
  border: 1px solid $secondary !important;
  background-color: #F5FAFE;
}

.focusedRow {
  border: 1px solid $secondary !important;
  background-color: $white;
}

.tickIcon path {
  stroke: $secondary;
}

.listInfo {
  color: $inactiveTextColor;
  background-color: #F7F8FA;
  font-size: 10px;

  span {
    border: 1px solid #DDE2ED;
    padding: 2px 12px;
    border-radius: 30px;
    font-size: 12px;
    color: $secondary;
  }
}

.type {
  color: $inactiveTextColor;
  font-size: 12px;
}