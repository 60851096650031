@import 'src/style/variables';

.wrapper {
  max-width: 42%;
}

.title {
  font-size: 48px;
  color: $textColor;
}

.subTitle {
  font-size: 18px;
  color: $inactiveTextColor;
}