@import 'src/style/variables';

.listItemWrapper {
  background: $white;
  border: 1px solid $borderColor;
  border-radius: $borderRadius;
}

.title {
  font-size: 16px;
  line-height: 19px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
}
.listItem {
  width: 85%;
}

.placeIcon {
  margin-left: -6px;
  margin-right: -6px;
}

.locationName {
  font-size: 14px;
  line-height: 16px;
  color: $inactiveTextColor;
}
