@import '../../style/variables';

.btn {
  border-radius: 2px;
  padding: 7px 0;
  font-weight: 500;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:focus,
  &:hover {
    box-shadow: none;
  }
}
.primary {
  background-color: $secondary;
  color: $white;
  border: none !important;

  &:focus,
  &:hover {
    color: $white;
    transition: background-color 0.2s linear;
    background-color: darken($secondary, 10%);
  }
}
.light {
  color: $inactiveTextColor;
  background-color: transparent;
  font-weight: 400;
  border: none !important;
}

.secondary {
  border: 2px solid $borderColor;
  color: $textColor;

  &:focus,
  &:hover {
    transition: background-color 0.2s linear;
    background-color: $interactiveInputColor;
    border: 2px solid $borderColor;
  }

}

.buttonLoading {
  background-color: inherit;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: #ffffff;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
  }
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}