@import "src/style/variables";

.title {
  font-size: 18px;
  color: $textColor;
  text-transform: capitalize;
}

.listTitle {
  color: $textColor;
  font-size: 14px;
}

.input::placeholder {
  color: $inactiveTextColor;
}

.create {
  font-size: 16px;
  color: $textColor;
}

.scrollable {
  height: 200px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    transition: background-color 0.2s linear;
    background-color: $secondary;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $primary;
  }
}
