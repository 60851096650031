@import 'src/style/variables';

.wrapper {
  max-height: 100%;
  overflow-y: scroll;
  transform: translateX(1px);
  width: 303px;
  box-shadow: 4px 4px 4px rgba(49, 59, 80, 0.08);
  position: relative;
  z-index: 2;
  user-select: text;

  &::-webkit-scrollbar {
    display: block;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    margin-top: 50px;
    margin-bottom: 50px;
    background-color: $white;
  }

  &::-webkit-scrollbar-thumb {
    background-color:$borderColor;
    width: 4px;
    border-radius: 56px;
    transition: background-color 0.2s linear;

    &:hover {
      background-color: darken($borderColor, 10%);
    }
  }
}

.leftWrapper {
  max-height: calc(100% - 38px) !important;
}

.contact {
  padding: 24px;
  border-bottom: 1px solid #DDE2ED;
}

.role {
  font-size: 16px;
  color: $textColor;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.name {
  font-size: 16px;
  color: $textColor;
  font-weight: 500;
}

.more {
  font-size: 14px;
  margin-left: 35px;
}

.divider {
  height: 1px;
  background-color: #DDE2ED;
}

.favoriteIcon {
  cursor: pointer;

  &.filled {
    & path {
      stroke: $badge;
      fill: $badge;
    }
  }
}

.locked {
  filter: blur(4px);
}

.lockIcon {
  width: 13px;
  height: auto;
  margin-right: 5px;

  & path {
    fill: $textColor;
  }
}
