@import 'src/style/variables';

.tableWrapper {
  border-radius: $borderRadius;
}

.status {
  padding: 5px 7px;
  text-transform: uppercase;
  font-size: 12px;
  background: $white;
  border: 1px solid $borderColor;
  border-radius: $borderRadius;
}

.progressBar {
  top: -4px;
}