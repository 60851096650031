@import '../../style/variables';


.label {
  color: $textColor;
  margin-bottom: 10px;
  line-height: 1;
  font-weight: 500;
  font-size: 14px;
}

.select {
  padding: 8px 12px;
  border: 2px $borderColor solid;
  border-radius: $borderRadius;
  font-size: 14px;
  line-height: 1.5;
  background-color: $white;
  transition: background-color 0.2s linear, color 0.2s linear, border 0.2s linear;
  user-select: none;

  & .value {
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
   font-weight: 400;
 }

  &:hover {
    background-color: #e3efff;
  }

  &.activeSelect {
    background-color: $secondary;
    color: $white !important;
    border: 2px $secondary solid;
  }
}
.icon {
  transform: translateY(-45%);
  right: 12px;

  & svg {
    transition: transform 0.2s linear;
  }

  &.active svg {
    transform: rotate(180deg);

    & path {
      stroke: $white;
    }
  }

  &.activeColored {
    & svg {
      transform: rotate(0);

      & path {
        stroke: $secondary;
      }
    }
  }
}

.optionWrapper {
  top: calc(100% + 8px);
  left: 0;
  box-shadow: 0 8px 10px rgba(81, 90, 105, 0.14),
              0 3px 14px rgba(81, 90, 105, 0.32);
  border-top: none;
  position: absolute;
  max-height: 275px;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 100;

  &[data-scrollable="true"] {
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
      transition: background-color 0.2s linear;
      background-color: $secondary;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: $primary;
    }
  }

  &::-webkit-scrollbar {
    width: 0;
  }

  &:empty {
    display: none;
  }
}

.option {
  padding: 12px;
  border: none;
  background-color: $white;
  transition: background-color 0.1s linear, color 0.1s linear, border 0.1s linear;
  font-weight: 400;

  &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  &.active {
    color: $secondary !important;
    background-color: #F5FAFE;
    border: 1px solid $secondary;
    border-radius: 2px;
  }

  &:hover {
    color: $secondary !important;
    background-color: #F5FAFE;
  }
}

.tickIcon path{
  stroke: $secondary;
}

.resetIcon {
  max-width: 18px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;

  &.resetOpened path {
    stroke: $white;
  }
}