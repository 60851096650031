@import 'src/style/variables';

.wrapper {
  padding: 90px 0;
  background-color: #F6F9FF;
}

.title {
  font-size: 48px;
  color: $textColor;
}

.subTitle {
  font-size: 18px;
  color: $inactiveTextColor;
  line-height: 1.8;
}

.cardWrapper {
  padding: 30px;
  box-shadow: 0 9px 16px rgba(205, 210, 223, 0.2);
  border-radius: $borderRadius;
  border-bottom: 2px solid transparent;
  margin-right: 40px;
  flex: 1;

  &:last-child {
    margin-right: 0;
  }
}

.cardTitle {
  font-size: 24px;
  color: $textColor;
}

.exploreBtn {
  padding: 12px 32px;
}
