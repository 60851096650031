@import "src/style/variables";

.modal {
  background: rgba(11, 16, 41, 0.21);
  z-index: 999;
}

.body {
  width: 776px;
  background-color: #F0F4F8;
  padding-bottom: 36px !important;
}

.title {
  color: $textColor;
  font-size: 30px;
}

.subTitle {
  color: $inactiveTextColor;
  font-size: 16px;
}

.btn {
  font-size: 14px;
  color: $inactiveTextColor;
  box-shadow: inset 0 1px 4px rgba(47, 49, 57, 0.1);
  border-radius: $borderRadius;
}

.close {
  top: 25px;
  right: 25px;
}

.plan {
  width: 48%;
  background: $white;
  box-shadow: 0 1px 8px rgba(205, 210, 223, 0.22);
  border-radius: $borderRadius;

  [data-plan-type] {
    background-image: url('../../../assets/icons/city.svg');
    background-position: bottom 0 right 10px;
    background-repeat: no-repeat;
    background-size: initial;
  }

  [data-plan-type="national"] {
    background-image: url('../../../assets/icons/national.svg');
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: initial;
  }
}

.planActive {
  background-color: $secondary;
}

.price {
  color: $secondary;
  font-size: 14px;
  min-height: 21px;

  .accent {
    font-size: 30px;
    font-weight: 500;
  }
}

.divider {
  height: 1px;
  background-color: #F0F4F8;
}

.planLabel {
  font-size: 14px;
  color: $textColor;
}

.planText {
  font-size: 14px;
  color: $inactiveTextColor;

  & b {
    font-weight: 500;
  }
}

.planBtn {
  height: 42px;

  & b {
    font-weight: 500;
  }
}

.save {
  color: $secondary;
  font-size: 14px;
}

.placeholder {
  color: $inactiveTextColor;
  padding: 0 14px;
  font-size: 14px;
  transition: background-color 0.2s linear;

  &:hover {
    background-color: $interactiveInputColor;
  }
}

.cardInputs {
  z-index: 10;
}

.monthInput,
.cvvInput {
  width: 75px;
  font-size: 14px;

  &:focus {
    z-index: 10;
  }
}

.current {
  font-size: 12px;
  color: #AAB2CC;
}

.planTitle {
  color: $textColor;
  font-size: 16px;
}
