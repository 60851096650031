@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';
@import "src/style/variables";

.slick-slide {
  padding: 0 10px;
}

.slick-dots {
  & li {
    margin: 0 50px 0 0;

    &:last-child {
      margin-right: 0;
    }

    & button:before {
      font-size: 12px;
      color: #D6DCE7;
    }

    &.slick-active button:before {
      color: $secondary !important;
      opacity: 1;
    }
  }
}
