@import "src/style/variables";

.container {
  padding: 90px 0 120px;
  max-width: 872px;
}

.arrow {
  &:first-child {
    margin-right: 64px;
  }
  &:last-child {
    margin-left: 64px;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.title {
  font-size: 48px;
  color: $textColor;
}

.photo {
  width: 104px;
  height: 104px;
  z-index: 40;
}

.report {
  padding: 104px 90px 56px;
  box-shadow:  0 8px 10px rgba(81, 90, 105, 0.14),
               0 3px 14px rgba(81, 90, 105, 0.12);
  border-radius: $borderRadius;
  margin-top: -52px;
  z-index: 30;
}
.quote {
  font-size: 18px;
  color: $textColor;
  line-height: 2rem;
  min-height: 6rem;
}

.reportTitle {
  color: $textColor;
  font-size: 24px;
}

.position {
  font-size: 18px;
  color: $inactiveTextColor;
}

.dot {
  background-color: #D6DCE7;
  width: 6px;
  height: 6px;
  margin-right: 56px;
  transition: .2s linear background-color;

  &:last-child {
    margin-right: 0;
  }

  &.active {
    background-color: $secondary;
  }
}

.shadow,
.shadow2 {
  width: 90%;
  height: 16px;
  margin: 0 auto;
  border-bottom-left-radius: $borderRadius;
  border-bottom-right-radius: $borderRadius;
  box-shadow:  0 8px 10px rgba(81, 90, 105, 0.14),
               0 3px 14px rgba(81, 90, 105, 0.12);
  position: relative;
  z-index: 20;
}

.shadow2  {
  width: 82%;
  z-index: 10;
}