@import "src/style/variables";

.paymentForm {
  background-color: #F0F4F8;
}

.paymentTitle {
  font-size: 14px;
  color: $textColor;
}

.columnPaymentBtn {
  width: 245px;
}

.paymentButton {
  height: 48px;
  font-size: 14px;
}

.cvvInput {
  width: 55px;
}

.paymentAccent {
  font-size: 24px;
  font-weight: 400;
}

.promoButton {
  font-size: 14px;
}

.disabledPromoButton {
  background-color: #DFE5EB;
}

.paymentButton {
  background-color: #00C47D;



  &:hover {
    background-color:darken(#00C47D, 10%);
  }
}

.disabledPaymentButton {
  background-color: #64D69F;
}

.formLabel {
  font-size: 12px;
  color: #AAB2CC;
}

.price {
  color: $secondary;
  font-size: 14px;

  .accent {
    font-size: 30px;
    font-weight: 500;
  }
}

.planTitle {
  color: $textColor;
  font-size: 16px;
}

.planText {
  font-size: 14px;
  color: $inactiveTextColor;

  & b {
    font-weight: 500;
  }
}

.paymentInfo {
  width: 255px;
}

.current {
  font-size: 12px;
  color: #AAB2CC;
}